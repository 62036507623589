import * as React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

export const bunkerToS3Path = (path?: string) => {
	if (!path) return ''
	if (!process.env.NEXT_PUBLIC_STATIC_ASSETS_PATH) return path
	if (path.startsWith('https://bunker.hungry.dk')) {
		return path.replace(
			'https://bunker.hungry.dk',
			process.env.NEXT_PUBLIC_STATIC_ASSETS_PATH
		)
	}
	if (path.startsWith('https://staging-bunker.hungry.dk')) {
		return path.replace(
			'https://staging-bunker.hungry.dk',
			process.env.NEXT_PUBLIC_STATIC_ASSETS_PATH
		)
	}
	return path
}

type Dimension = { width: number; height: number; inside?: boolean }
// Must be called after path is converted to cdn path
export const resizedUrl = (path: string, dimensions: Dimension) => {
	// Figure out a better way to check if url has already been resized
	if (path.includes('490x205')) return path
	if (!path.startsWith('http')) {
		path = `${process.env.NEXT_PUBLIC_STATIC_ASSETS_PATH}/media/${path}`
	}
	if (dimensions.inside) {
		return path.replace(
			'.net/',
			`.net/${dimensions.width}x${dimensions.height}xI/`
		)
	}

	return path.replace('.net/', `.net/${dimensions.width}x${dimensions.height}/`)
}

//TODO: move exported functions to helpers
export const createSrcSet = (path: string, dimensions: Dimension[]) => {
	return dimensions.map((dm) => ({
		width: dm.width,
		url: resizedUrl(path, {
			width: dm.width,
			height: dm.height,
		}),
	}))
}

type Props = {
	src?: string
	supportWebp?: boolean
	fallbackSrc?: string
	skeletonHeight?: number
	aspectRatio?: number
	className?: string
} & React.ImgHTMLAttributes<HTMLPictureElement>

const Wrapper = styled.picture<{ aspectRatio?: number }>`
	position: relative;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
	}

	.react-loading-skeleton {
		position: absolute;
		right: 0;
		bottom: 0;
		top: 0;
		left: 0;
	}

	${(props) =>
		props.aspectRatio &&
		`
		padding-top: ${props.aspectRatio * 100}%;
		& > source, img {
			transform: translate(-50%, -50%);
			position: absolute;
			right: 0;
			bottom: 0;
			top: 50%;
			left: 50%;
		}
	`}
`

const Image = ({
	src,
	fallbackSrc,
	aspectRatio,
	supportWebp,
	...rest
}: Props) => {
	const [imageSrc, setImageSrc] = React.useState(() => bunkerToS3Path(src))
	const [loaded, setLoaded] = React.useState(false)
	const ref = React.useRef<HTMLImageElement>(null)

	React.useEffect(() => {
		setImageSrc(bunkerToS3Path(src))
	}, [src])

	// This is to support SSR
	React.useEffect(() => {
		// Basically React attaches onLoad handler after js is loaded
		// However the image may already be loaded at this point
		if (ref.current && ref.current.complete) {
			setLoaded(true)
		}
	}, [ref])

	return (
		<Wrapper
			aspectRatio={aspectRatio}
			draggable="false"
			onLoadStart={() => setLoaded(false)}
			onLoad={() => setLoaded(true)}
			{...rest}
		>
			{supportWebp && <source srcSet={imageSrc + '.webp'} type="image/webp" />}

			<img
				ref={ref}
				src={imageSrc}
				onError={() => {
					if (fallbackSrc) {
						setImageSrc(fallbackSrc)
					}
				}}
			/>
			{(!loaded || !imageSrc) && <Skeleton />}
		</Wrapper>
	)
}

export default Image
